import React, { useState } from "react";
import Select from "react-dropdown-select";
import store from "traec/redux/store";
import Traec from "traec";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "traec-react/errors/handleError";

const blobToDownload = (blob, fileName = "report.pdf") => {
  let url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const makeReport = (payload) => {
  console.log("Making report with payload data", payload?.toJS());

  let url = "/api/report/pdf";
  let state = store.getState();

  let token = state.getInPath("auth.token");
  console.log("Calling api report with token", url, token);

  fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.blob())
    .then((blob) => blobToDownload(blob))
    .catch((err) => console.warn("Error getting report", err));
};

function InputField({ _key, label, state, setState }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let value = state.get(_key) || "";
  return (
    <div className="form-group">
      <label htmlFor={_id}>{label}</label>
      <input
        id={_id}
        className="form-control"
        type="text"
        placeholder=""
        value={value}
        onChange={(e) => setState(state.set(_key, e.target.value))}
      />
    </div>
  );
}

function TextAreaField({ _key, label, state, setState }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let value = state.get(_key) || "";
  return (
    <div className="form-group">
      <label htmlFor={_id}>{label}</label>
      <textarea
        className="form-control"
        id={_id}
        rows="3"
        value={value}
        onChange={(e) => setState(state.set(_key, e.target.value))}
      />
    </div>
  );
}

function TinyMCEInputField({ _key, label, state, setState }) {
  return (
    <ErrorBoundary>
      <div className={`form-group`}>
        <label>{label}</label>
        <TinyMCE initialValue={initialContent} init={config} onEditorChange={(v) => setState(state.set(_key, v))} />
        <ItemErrors error={error} />
      </div>
    </ErrorBoundary>
  );
}

function SelectField({ _key, label, options, state, setState, listType = "list_ul" }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let values = (state.getInPath(`${_key}.data`) || Traec.Im.List())?.toJS();

  let _options = (options || []).map((i) => ({ name: i }));

  return (
    <div className="form-group">
      <label htmlFor={_id}>{label}</label>
      <Select
        multi={true}
        clearable={true}
        labelField={"name"}
        valueField={"name"}
        className="form-control"
        id={_id}
        options={_options}
        values={values}
        onChange={(values) => {
          let fieldData = {
            type: listType,
            data: values.map((i) => i.value || i.name),
          };
          setState(state.set(_key, Traec.Im.fromJS(fieldData)));
        }}
      />
    </div>
  );
}

export default function SBCCReport(props) {
  let { _type, _typeId } = props.match?.params;
  let [state, setState] = useState(
    Traec.Im.Map({
      baseline_year: "2022/23",
      standard_sectors: "Rail Infrastructure",
      lt_reduction_percent: 70,
      lt_reduction_years: 50,
      reduction_percent_2030: 25,
    })
  );

  let _props = { state, setState };
  console.log("Current state", state?.toJS());

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          makeReport(state);
        }}
      >
        <InputField {..._props} _key="company_name" label="Company Name" />
        <TextAreaField {..._props} _key="activity_description" label="Activity description" />
        <SelectField
          {..._props}
          _key="hotspot_selections"
          label="Hotspot selections"
          listType="list_ul"
          options={[
            "Company Owned Vehicle Transport] - [Scope One]",
            "Energy - Lighting and Heating of Company Owned Buildings - [Scope Two]",
            "Waste Generated from sale of goods - [Scope Three]",
          ]}
        />
        <SelectField
          {..._props}
          _key="drivers_for_action"
          label="Drivers for action"
          listType="list_ol"
          options={[
            "To reduce costs",
            "To meet the growing depand of our customers",
            "To combat climate risks to our business",
          ]}
        />
        <SelectField
          {..._props}
          _key="quick_wins"
          label="Quick wins"
          listType="list_ul"
          options={[
            "Establish a monitoring and targeting system to record data on energy use",
            "All staff to complete online SBCC carbon reduction training modules",
            "Upgrade Lighting & Install Controls - possible savings of £XX (XX CO2 tonnes)",
            "Establish a budget for investing in energy efficiency",
            "Improving  the  way  we  sort  our  waste  in  order  to  reduce  the  amount  sent  to  landfill,  a possible saving of £XX (XX CO2 tonnes)",
            "Where possible, to use energy from sustainable sources e.g. renewable energy",
          ]}
        />
        <SelectField
          {..._props}
          _key="core_actions"
          label="Core actions"
          listType="list_ul"
          options={[
            "Review low carbon alternatives for the top ten products / raw materials we purchase.",
            "Reduce  environmental  emissions  associated  with  travel  (including  employee  travel  to work, business travel and distribution of goods)",
            "Identify future cost-effective energy efficiency measures to implement",
            "Establish procedures for energy efficient operation of plant and equipment.",
            "Consider life-cycle energy costs for all new projects and modifications to existing plant and machinery.",
          ]}
        />
        <InputField {..._props} _key="lt_reduction_percent" label="Long term carbon reduction target [percent]" />
        <InputField {..._props} _key="lt_reduction_years" label="Long term carbon reduction timeframe [years]" />
        <InputField {..._props} _key="reduction_percent_2030" label="Carbon reduction target for 2030 [percent]" />
        <button className="btn btn-sm btn-primary" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}
