import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Traec from "traec";
import { connect } from "react-redux";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { RelatedRefs } from "AppSrc/user/projectItem/relatedRefs";
import { RelatedCommits } from "AppSrc/user/projectItem/relatedCommits";
import { BSBtn, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { isSuperuser } from "traec-react/utils";
import { companyFields } from "../company";
import Octicon from "react-octicon";

const MODAL_ID = "CommonCompanyAddModal001";

function ProjectRow(props) {
  let { project, refs, depth } = props;
  if (!project) {
    return null;
  }

  let _projectId = (project?.get("uid") || "").substring(0, 8);
  return (
    <ErrorBoundary>
      <div className="row hover-row" style={{ marginLeft: `${depth - 1}em`, minHeight: "39px" }}>
        <u>
          <Link className="" to={`/project/${_projectId}`}>
            {project?.get("name")}
          </Link>
        </u>
      </div>
      <RelatedRefs project={project} depth={depth + 1} />
      <RelatedCommits project={project} refs={refs} depth={depth + 1} />
    </ErrorBoundary>
  );
}

function CompanyRow(props) {
  let { company, companies, projects } = props;
  let [collapsed, setCollapsed] = useState(false);

  let depth = company.get("depth") || 0;
  let parentCompany = depth === 1;
  let childids = company.get("childids") || Traec.Im.List();
  let children = childids.map((i) => companies.get(i));

  let childProjectNameIds = company.get("projects") || Traec.Im.List();
  let childProjects = childProjectNameIds
    .sortBy((project) => project.get("name"))
    .map((project, i) => (
      <ProjectRow key={i} {...props} project={projects.get(project.get("uid"))} depth={depth + 1} />
    ));

  let _companyId = (company?.get("uid") || "").substring(0, 8);

  return (
    <ErrorBoundary>
      <div
        className={`d-flex justify-content-between hover-row py-1 ${parentCompany ? "my-3" : "my-2"}`}
        style={{ marginLeft: `${depth - 1}em`, cursor: "pointer", minHeight: "39px" }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Link
          className={`font-weight-bold ${parentCompany ? "font-weight-bold text-primary lead" : ""}`}
          to={`/company/${_companyId}`}
        >
          <u>{company?.get("name")}</u>
        </Link>
        {depth === 1 ? (
          <Link to={`/company/${_companyId}`}>
            <button className="btn btn-sm btn-primary">Go to main dashboard</button>
          </Link>
        ) : null}
      </div>
      {collapsed ? null : (
        <ErrorBoundary>
          {children
            .sortBy((i) => i.get("name"))
            .map((child, i) => (
              <CompanyRow key={i} {...props} company={child} />
            ))}
          {childProjects}
        </ErrorBoundary>
      )}
    </ErrorBoundary>
  );
}

function UserNotificationTree(props) {
  let { companies, user } = props;
  let [state, setState] = useState({});

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: [
        new Traec.Fetch("project", "list"),
        new Traec.Fetch("company", "list"),
        new Traec.Fetch("tracker_ref_all", "list"),
        new Traec.Fetch("tracker_commit_all", "list"),
      ],
    });
  });

  let rootCompanies = companies.filter((i) => i.get("depth") <= 1);
  //console.log("Got root companies", rootCompanies.size, rootCompanies?.toJS());

  let tree = rootCompanies
    .toList()
    .sortBy((i) => i.get("name"))
    .map((company, i) => <CompanyRow key={i} {...props} company={company} />);

  let fetch = new Traec.Fetch("company", "post");
  fetch.updateFetchParams({
    postSuccessHook: () => $(`#${MODAL_ID}`).modal("hide"),
  });
  return (
    <ErrorBoundary>
      <BSModal
        title="Add a Company Account"
        id={MODAL_ID}
        body={
          <BaseFormConnected params={fetch.params} fields={companyFields} forceShowForm={true} hideUnderline={true} />
        }
      />
      {isSuperuser(user) ? (
        <div className="my-5">
          <h4 className="font-weight-bold">Admins</h4>{" "}
          <div className="shadow p-3 d-flex justify-content-between align-items-center">
            <p className="text-muted m-0">Click Create a Company to register a new company account.</p>
            <button className="btn btn-sm btn-primary" onClick={(e) => $(`#${MODAL_ID}`).modal("show")}>
              Create a Company
            </button>
          </div>
        </div>
      ) : null}
      <h4 className="font-weight-bold mt-5">Dashboards and reports</h4>
      <p className="text-muted">
        Click on the company or project names to view their dashboard. Click on individual reports to see their
        progress.
      </p>
      <div className="shadow p-4 mb-5">{tree}</div>
    </ErrorBoundary>
  );
}

const mapStateToProps = (state, ownProps) => {
  let projects = state.getInPath("entities.projects.byId") || Traec.Im.Map();
  let companies = state.getInPath("entities.companies.byId") || Traec.Im.Map();
  let userRefs = state.getInPath("entities.refs.byId") || Traec.Im.Map();
  let commits = state.getInPath("entities.commits.byId") || Traec.Im.Map();

  // Map the responsible refs onto the list of projects
  let activeProjectRefs = userRefs
    .toList()
    .filter((cref) => cref.getInPath("latest_commit.discipline") && cref.get("depth") > 1)
    .map((cref) => {
      let projectId = cref.get("project");
      if (projects.get(projectId)) {
        projects = projects.addListToSet(`${projectId}.related_refs`, [cref]);
      }
      return cref;
    });

  // Map the responsible commits onto  the list of projects
  commits
    .filter(
      (commit) =>
        commit &&
        Traec.Im.isImmutable(commit) &&
        commit.getInPath("meta_json.actionRequiredBy") &&
        commit.get("reporting_period")
    )
    .map((commit) => {
      let projectId = commit.get("project");
      if (projects.get(projectId)) {
        projects = projects.addListToSet(`${projectId}.related_commits`, [commit]);
      }
    });

  return {
    projects,
    activeProjectRefs,
    refs: userRefs,
    commits,
    companies,
    user: state.getInPath("auth.user"),
  };
};

export default connect(mapStateToProps)(UserNotificationTree);
