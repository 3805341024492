import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Footer } from "AppSrc/footer";
import BootstrapSplitPane from "traec-react/utils/bootstrap/splitbs";
import AdminRouter from "./router";

import { PermissionWarning } from "traec/utils/permissions/project";
import { ErrorBoundary } from "traec-react/errors";

function SideBarLinks({ show }) {
  if (!show) {
    return null;
  }
  return (
    <div className="mt-4">
      <p>
        <Link to="/tenant/admin/basemetric">Base Metrics</Link>
      </p>
      <p>
        <Link to="/tenant/admin/indicator">Base Indicators</Link>
      </p>
      <p>
        <Link to="/tenant/admin/conversion">Conversion Factors</Link>
      </p>
      <p>
        <Link to="/tenant/admin/subtenant">Sub-Tenancies</Link>
      </p>
      <p>
        <Link to="/tenant/admin/version">Version</Link>
      </p>
    </div>
  );
}

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: "",
      fetchedUrls: {},
      setNavBar: false,
      showSideBar: true,
    };
  }

  render() {
    // Check the User permissions for this project
    let { user } = this.props;

    // Return a simple permission denied page
    if (!(user && user.get("is_tenant_superuser"))) {
      return <PermissionWarning />;
    }

    // Render the page and sidebar
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <BootstrapSplitPane
              localStorageKey={`explorer-grid-split-admin`}
              allowZero={true}
              pane1ClassName={"page-sidebar vh100-navbar"}
              onCollapseHook={() => {
                this.setState({ showSideBar: false });
              }}
              onExpandHook={() => {
                this.setState({ showSideBar: true });
              }}
              pane1Style={{
                borderRight: "1px solid grey",
              }}
            >
              <div>
                <ErrorBoundary>
                  <SideBarLinks show={this.state.showSideBar} />
                </ErrorBoundary>
              </div>

              <div>
                <ErrorBoundary>
                  <AdminRouter />
                </ErrorBoundary>
              </div>
            </BootstrapSplitPane>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let isAuthenticated = state.getInPath("auth.isAuthenticated");
  let tokenData = state.getInPath("auth.decoded_token");
  let user = state.getInPath("auth.user");
  return { isAuthenticated, tokenData, user };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
