import React from "react";

export default class AdminHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <h3>Tenancy Admin</h3>
        <p>
          These pages perform site-wide operations that can affect the whole site (or sub-tenancy). Which may be very
          difficult to reverse.
        </p>
        <p>
          <b>
            Please use with caution and test any changes you will make on the test-site before applying them to the live
            data on production.
          </b>
        </p>
        <p>Edit and adjust core units, metrics, indicators, etc. across the whole tenancy.</p>
      </React.Fragment>
    );
  }
}
