import React from "react";
import { connect } from "react-redux";

import Traec from "traec";

import { BSCard, BSBtn, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import CompanyItem from "./companyItem";
import { isSuperuser } from "traec-react/utils";

export const companyFields = {
  name: { value: "", class: "col", endRow: true },
  address: { value: "", class: "col", endRow: true },
  address2: { value: "", class: "col", endRow: true },
  suburb: { value: "", class: "col" },
  postcode: { value: "", class: "col" },
  state: { value: "", class: "col" },
  country: { value: "", class: "col", endRow: true },
};

export const counter = { row: 0 };

const MODAL_ID = "CommonCompanyAddModal001";

function CompanyAddButton({ user }) {
  if (isSuperuser(user)) {
    return <BSBtn className="my-2" onClick={(e) => $(`#${MODAL_ID}`).modal("show")} text="Create a Company" />;
  }
  return null;
}

class UserCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Data required from the API for this Component
    this.requiredFetches = [new Traec.Fetch("company", "list")];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { items, dispatch, user } = this.props;
    if (!items) {
      return null;
    }

    let itemList = items
      .toList()
      .sortBy((obj, i) => obj.get("created"))
      .filter((obj) => obj.get("parentid") == null)
      .map((item, i) => (
        <CompanyItem
          key={i}
          index={i}
          indentLevel={0}
          company={item}
          allCompanies={items}
          dispatch={dispatch}
          user={user}
        />
      ));

    let fetch = new Traec.Fetch("company", "post");
    fetch.updateFetchParams({
      postSuccessHook: () => $(`#${MODAL_ID}`).modal("hide"),
    });

    counter.row = 0;
    return (
      <BSCard
        id="user-companies"
        widthOffset="col-sm-12"
        title="My Company Memberships"
        button={<CompanyAddButton user={user} />}
        body={itemList}
        form={
          <BSModal
            title="Add a Company Account"
            id={MODAL_ID}
            body={
              <BaseFormConnected
                params={fetch.params}
                fields={companyFields}
                forceShowForm={true}
                hideUnderline={true}
              />
            }
          />
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: state.getInPath("entities.companies.byId"),
  newItem: state.getInPath("entities.projects.newItem"),
  isAuthenticated: state.getInPath("auth.isAuthenticated"),
  user: state.getInPath("auth.user"),
});

export default connect(mapStateToProps)(UserCompanies);
