import React from "react";
import Octicon from "react-octicon";

export function SupportDropdown() {
  return (
    <React.Fragment>
      <li className="nav-item">
        <a className="nav-link" href="/accounts/profile/">
          <Octicon name="bell" />
        </a>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Octicon name="question" scale={1.5} />
        </a>
        <div className={`dropdown-menu dropdown-menu-right`} aria-labelledby="navbarDropdown">
          <a
            href="https://docs.sustainabilitytool.com"
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
          >
            User Guide
          </a>
          <a
            href="mailto:info@sustainabilitytool.com?subject=Sustainability Tool - message support&body=Please complete the details below and send the email. The Sustainability Tool team will then be glad to support your query. %0D%0A %0D%0A
              1. Full name: %0D%0A
              2. Company name: %0D%0A
              3. The URL (link) of the page you require support with: %0D%0A
              4. A screenshot of the page (if possible): %0D%0A
              5. A short description of your issue or query: %0D%0A %0D%0A
              We aim to respond to all our queries within 24 hours."
            className="dropdown-item"
            target="_blank"
          >
            Message Support
          </a>
        </div>
      </li>
    </React.Fragment>
  );
}

export function SupportDropdownV2() {
  return (
    <React.Fragment>
      <li className="nav-item">
        <a className="nav-link" href="/accounts/profile/">
          <Octicon name="bell" />
        </a>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Octicon name="question" scale={1.5} />
        </a>
        <div className={`dropdown-menu dropdown-menu-right`} aria-labelledby="navbarDropdown">
          <a
            href="https://docs.sustainabilitytool.com"
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
          >
            User Guide
          </a>
          <a
            href="mailto:info@sustainabilitytool.com?subject=Sustainability Tool - message support&body=Please complete the details below and send the email. The Sustainability Tool team will then be glad to support your query. %0D%0A %0D%0A 
              1. Full name: %0D%0A 
              2. Company name: %0D%0A 
              3. The URL (link) of the page you require support with: %0D%0A 
              4. A screenshot of the page (if possible): %0D%0A 
              5. A short description of your issue or query: %0D%0A %0D%0A
              We aim to respond to all our queries within 24 hours."
            className="dropdown-item"
            target="_blank"
          >
            Message Support
          </a>
          <Link to="/terms" className="dropdown-item">
            Terms of Service & Privacy Policy
          </Link>
        </div>
      </li>
    </React.Fragment>
  );
}
