import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import BaseFormConnected from "traec-react/utils/form";
import CompanyInviteItem from "./companyInviteItem";
import Octicon from "react-octicon";

export const companyFields = {
  name: { value: "", class: "col", endRow: true },
  address: { value: "", class: "col", endRow: true },
  address2: { value: "", class: "col", endRow: true },
  suburb: { value: "", class: "col" },
  postcode: { value: "", class: "col" },
  state: { value: "", class: "col" },
  country: { value: "", class: "col", endRow: true },
};

class CompanyInvites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      fetchedInvites: false,
    };

    // Data required from the API for this Component
    this.requiredFetches = [new Traec.Fetch("company_invite_all", "list")];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { items } = this.props;
    if (!items || !items.size) {
      return (
        <div className="d-flex flex-column align-items-center">
          <Octicon name="verified" mega className="text-success my-2" />
          <p className="mb-0">No outstanding Company Invites</p>
        </div>
      );
    }

    const itemList = items
      .toList()
      .map((item, i) => (
        <CompanyInviteItem
          key={i}
          index={i}
          invite={item}
          dispatch={this.props.dispatch}
          onAcceptReject={this.acceptRejectReload}
        />
      ));

    return (
      <>
        {itemList}
        <BaseFormConnected
          stateParams={this.state.formParams.stateParams}
          fetchParams={this.state.formParams.fetchParams}
          fields={companyFields}
        />
      </>
    );
  }
}

export const companyInvitesSize = (props) => {
  return props?.items;
};

const mapStateToProps = (state, ownProps) => ({
  items: state.getInPath("entities.companyInvites.byId")?.filter((i) => i?.getInPath("company.name")),
  isAuthenticated: state.getInPath("auth.isAuthenticated"),
});

export default connect(mapStateToProps)(CompanyInvites);
