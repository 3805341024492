import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { verifyToken } from "storybook-dashboard/auth/token";
import { LOGIN_SUCCESS, LOGIN_STATUS, LOGIN_FAILURE } from "traec-react/auth/_redux/types";

export function Spinner() {
  return (
    <div className="text-center">
      <div className="lds-dual-ring"></div>
    </div>
  );
}

const noLoginRequired = (url) => {
  return (
    url == "/" ||
    url.startsWith("/accounts/login") ||
    url.startsWith("/accounts/password/reset") ||
    url.startsWith("/accounts/reset") ||
    url.startsWith("/accounts/activate") ||
    url.startsWith("/accounts/register") ||
    url.startsWith("/home") ||
    url.startsWith("/terms")
  );
};

/* This component is rendered on every change of path or redirect.  
We use this to check the window.location and auth status and redirect accordingly
*/
function Redirector(props) {
  let { isAuthenticated, authStatus, requestedVerified } = props;
  const nextUrl = window.location.pathname;
  //console.log("Redirector RENDERING", authStatus, isAuthenticated, requestedVerified, nextUrl);

  // User is logged in - reidrect to /accounts/profile
  if (authStatus == "confirmed" && nextUrl == "/") {
    return <Redirect to={`/accounts/profile`} />;
  }

  // User logged out or login failed.  Redirect to login page
  // (unless page we are going to doesn't require a login)
  if (!isAuthenticated && (!authStatus || authStatus == "failed")) {
    if (!requestedVerified) {
      console.log("Redirector waiting for verification request response");
      return null;
    }
    if (!noLoginRequired(nextUrl)) {
      return (
        <Redirect
          to={{
            pathname: `/accounts/login`,
            state: { nextUrl: `${nextUrl}` },
          }}
        />
      );
    }
  }

  return null;
}

function LoginRedirect(props) {
  let { isAuthenticated, authStatus, children, dispatch } = props;

  // Send a request to verify or get a token
  let [requestedVerified, setRequestedVerified] = useState(false);
  useEffect(() => {
    if (!requestedVerified) {
      console.log("LOGIN REDIRECT dispatching token verification");
      if (localStorage.getItem("token") == "failed") {
        localStorage.removeItem("token");
      }
      dispatch({ type: LOGIN_STATUS, payload: { status: "pending" } });
      const token = localStorage.getItem("token");
      verifyToken(token);
      setRequestedVerified(true);
      let fetchParams = {
        url: "/auth-jwt/verify/",
        method: "POST",
        body: { token },
      };

      dispatch({
        APICallTypes: {
          successType: LOGIN_SUCCESS,
          failureType: LOGIN_FAILURE,
        },
        fetchParams,
      });
    }
  }, []);

  if (!isAuthenticated && authStatus == "pending") {
    return <Spinner />;
  }

  //const nextUrl = props.location.pathname;
  const nextUrl = window.location.pathname;
  console.log("LOGIN REDIRECT: ", isAuthenticated, authStatus, nextUrl);
  return (
    <React.Fragment>
      <Route
        path="/"
        render={(_props) => (
          <Redirector
            {..._props}
            isAuthenticated={isAuthenticated}
            authStatus={authStatus}
            requestedVerified={requestedVerified}
          />
        )}
      />
      {children}
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.getInPath("auth.isAuthenticated"),
    authStatus: state.getInPath("auth.status"),
  };
};

export default connect(mapStateToProps)(LoginRedirect);
