import React from "react";

function DescriptionColumn(props) {
  let { cellTitle, description } = props;
  return (
    <div className="info-descr eq-height">
      <div className="tbl-cell">
        <h2 itemProp="name">
          <span className="icon">
            <em className="fa fa-bar-chart"></em>
          </span>
          {cellTitle}
        </h2>
        {description}
      </div>
    </div>
  );
}

function HomeSection(props) {
  let { imagecol } = props;
  return (
    <section
      style={{ paddingTop: "5em" }}
      className="info-section home"
      id="section-01"
      itemScope
      itemType="http://schema.org/Service"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-5">{imagecol}</div>
          <div className="col-md-6 col-sm-7">
            <DescriptionColumn {...props} />
          </div>
        </div>
      </div>
    </section>
  );
}

function WhatIsDescription(props) {
  return (
    <React.Fragment>
      <p itemProp="description">
        The Sustainability Tool is a cloud based sustainability performance reporting system, which has been
        specifically designed &nbsp;to be used for supply chain management. It enables you to collect, report &amp;
        analyse social &amp; environmental sustainability performance using smart dashboards at project, business unit
        and corporate level.
      </p>
      <p itemProp="description">
        <a href="/contact/">Contact us to find out more about how the tool can help you&nbsp;&gt;</a>
      </p>
    </React.Fragment>
  );
}

function WhatIs(props) {
  return (
    <HomeSection
      cellTitle={"What is our tool?"}
      description={<WhatIsDescription />}
      imagecol={
        <iframe
          style={{ width: "100%" }}
          height="255"
          src="https://www.youtube.com/embed/UbGPrVfJgKY"
          frameBorder="0"
        ></iframe>
      }
    />
  );
}

function HowWorksDescription() {
  return (
    <React.Fragment>
      <p itemProp="description">
        View and manage performance at corporate, business unit and project level through smart dashboards and
        infographics. The tool is&nbsp;designed to mimic your supply chain and project management.&nbsp;
        <br />
        <br />
        At project level, the tool is designed to be used post-award of a contract.&nbsp;You choose what to measure, and
        delegate to your supply chain to report. You then view their performance in real time, allowing you to act
        quickly on &nbsp;any issues that arise.
        <br />
        <br />
        At business unit and corporate level, you can see the issues that your business are not performing well on, but
        also see the areas you are excelling on.
        <br />
        <br />
        <a href="/how/">Find out more about how the tool works &gt;</a>
      </p>
    </React.Fragment>
  );
}

function HowWorks(props) {
  return (
    <HomeSection
      descrRight={true}
      cellTitle={"How the tool works"}
      description={<HowWorksDescription />}
      imagecol={<img alt="" src="/assets/images/home/1BB.jpg" />}
    />
  );
}

function WhyUseDescription() {
  return (
    <React.Fragment>
      <p itemProp="description">
        You need to understand how your supply chain is working towards key sustainability targets.
        <br />
        <br />
        The Sustainability Tool is a one stop platform that allows you to monitor and manage the impact of your
        projects, without getting bogged down in spreadsheets and time consuming data collection.&nbsp;
        <br />
        <br />
        <a href="/benefits/">Find out more about the business benefits of the tool &gt;</a>
      </p>
    </React.Fragment>
  );
}

function WhyUse() {
  return (
    <HomeSection
      cellTitle={"Why use the tool"}
      description={<WhyUseDescription />}
      imagecol={
        <iframe
          style={{ width: "100%" }}
          height="255"
          src="https://www.youtube.com/embed/KOhK7UfFP4A"
          frameBorder="0"
        ></iframe>
      }
    />
  );
}

export function StaticContent() {
  return (
    <React.Fragment>
      <WhatIs />
      <HowWorks />
      <WhyUse />
    </React.Fragment>
  );
}
