import React from "react";

export function ActionButton({ text, color, onClickHandler }) {
  return (
    <button
      className={`btn btn-sm btn-${color || "primary"} rounded m-1 `}
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
    >
      {text}
    </button>
  );
}
