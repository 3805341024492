import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminHome from "./home";
import BaseMetrics from "./basemetrics";
import BaseIndicators from "./indicators";
import BaseConversions from "./conversions";
import SubTenants from "./tenants";
import Version from "./version";

export default class AdminRouter extends React.Component {
  render() {
    //const baseUrl = this.props.match.url;
    return (
      <React.Fragment>
        <Switch>
          {/* there will only ever be one child in the Switch */}

          {/* Page to Edit Specific base attributes */}
          <Route exact path={`/tenant/admin/basemetric`} component={BaseMetrics} />
          <Route exact path={`/tenant/admin/basemetric/:_companyId`} component={BaseMetrics} />
          <Route exact path={`/tenant/admin/indicator`} component={BaseIndicators} />
          <Route exact path={`/tenant/admin/conversion`} component={BaseConversions} />
          <Route exact path={`/tenant/admin/subtenant`} component={SubTenants} />
          <Route exact path={`/tenant/admin/version`} component={Version} />

          <Route component={AdminHome} />
        </Switch>
      </React.Fragment>
    );
  }
}
