import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { baseMetricFields, baseMetricFieldsWithCategory } from "./form";
import { setAndShowModal, clearModal } from "AppSrc/utils/modal";

const MODAL_ID = "CommonBaseMetricModal001";

const counter = { metric: 0 };

function ConversionFactorHeaders() {
  return (
    <div className="row font-weight-bold">
      <div className="col-sm-3">id</div>
      <div className="col-sm-3">toUnit</div>
      <div className="col-sm-3">factor</div>
      <div className="col-sm-3">isGlobal?</div>
    </div>
  );
}

function ConversionFactorRow({ cf }) {
  return (
    <div className="row">
      <div className="col-sm-3">{cf.get("uid").substring(0, 8)}</div>
      <div className="col-sm-3">{cf.get("toUnit")}</div>
      <div className="col-sm-3">{cf.get("factor")}</div>
      <div className="col-sm-3">{cf.get("is_global") ? "global" : "local"}</div>
    </div>
  );
}

function BaseMetricConversionFactors({ show, baseMetric }) {
  if (!show) {
    return null;
  }
  let [state, setState] = useState({});
  let [data, setData] = useState(Traec.Im.List());

  // Call the admin dispatch and set the response into this state
  let fetch = new Traec.Fetch(
    "tenant_admin_dispatch",
    "post",
    {},
    {
      preDispatchHook: (action) => {
        Object.assign(action.fetchParams, {
          body: {
            type: "METRIC_CONVERSION_FACTORS",
            payload: {
              basemetric_id: baseMetric.get("uid"),
            },
          },
        });
        Object.assign(action.stateParams, {
          stateSetFunc: (state, action) => {
            setData(Traec.Im.fromJS(action.payload));
            return state;
          },
        });
        return action;
      },
    }
  );

  // Called on mount and update
  useEffect(() => {
    Traec.fetchRequiredFor({
      props: {},
      state,
      setState,
      requiredFetches: [fetch],
    });
  });

  let conversions = data.sortBy((i) => i.get("is_global")).map((cf, i) => <ConversionFactorRow key={i} cf={cf} />);

  return (
    <div style={{ borderTop: "2px solid black", borderBottom: "1px solid black" }}>
      <ConversionFactorHeaders />
      {conversions}
    </div>
  );
}

function MetricRefRow({ _ref }) {
  let name = _ref.get("name");
  let projectId = _ref.get("project").substring(0, 8);
  let wpackId = _ref.get("uid").substring(0, 8);
  let url = name == "master" ? `/project/${projectId}` : `/project/${projectId}/wpack/${wpackId}`;
  name = name == "master" ? `Project: ${projectId}` : name;
  return (
    <div className="row">
      <div className="col-sm-12">
        <a
          href={url}
          onClick={(e) => {
            e.preventDefault();
            location.href = url;
          }}
        >
          {name}
        </a>
      </div>
    </div>
  );
}

function BaseMetricRefs({ show, baseMetric }) {
  if (!show) {
    return null;
  }
  let [state, setState] = useState({});
  let [data, setData] = useState(Traec.Im.List());

  // Call the admin dispatch and set the response into this state
  let fetch = new Traec.Fetch(
    "tenant_admin_dispatch",
    "post",
    {},
    {
      preDispatchHook: (action) => {
        Object.assign(action.fetchParams, {
          body: {
            type: "METRIC_REFS",
            payload: {
              basemetric_id: baseMetric.get("uid"),
            },
          },
        });
        Object.assign(action.stateParams, {
          stateSetFunc: (state, action) => {
            setData(Traec.Im.fromJS(action.payload));
            return state;
          },
        });
        return action;
      },
    }
  );

  // Called on mount and update
  useEffect(() => {
    Traec.fetchRequiredFor({
      props: {},
      state,
      setState,
      requiredFetches: [fetch],
    });
  });

  let refs = data.sortBy((i) => i.get("project")).map((_ref, i) => <MetricRefRow key={i} _ref={_ref} />);

  return <div style={{ borderTop: "2px solid black", borderBottom: "1px solid black" }}>{refs}</div>;
}

class BaseMetricRow extends React.Component {
  constructor(props) {
    super(props);

    // Get the fetch params to edit this BaseMetric
    this.state = {
      showConversions: false,
      showRefs: false,
    };

    this.editBaseMetric = this.editBaseMetric.bind(this);
    this.mergeBaseMetrics = this.mergeBaseMetrics.bind(this);
  }

  editBaseMetric(e) {
    e.preventDefault();
    let { baseMetric, categories } = this.props;

    let fetch = new Traec.Fetch("tenant_admin_basemetric", "patch", {
      baseMetricId: baseMetric ? baseMetric.get("uid") : null,
    });
    fetch.updateFetchParams({
      postSuccessHook: () => {
        console.log("Sucessfully edited base metric. Hiding modal", MODAL_ID);
        $(MODAL_ID).modal("hide");
        //clearModal(MODAL_ID)
      },
    });

    setAndShowModal(MODAL_ID, {
      title: "Edit Base Metric",
      body: (
        <BaseFormConnected
          params={fetch.params}
          fields={Traec.Im.fromJS(baseMetricFieldsWithCategory(categories))}
          initFields={baseMetric.set("conversion_base", (baseMetric.get("conversion_base") || "").substring(0, 8))}
          forceShowForm={true}
          hideUnderline={true}
        />
      ),
    });
  }

  mergeBaseMetrics(e) {
    e.preventDefault();
    let { baseMetric } = this.props;
    let baseMetricId = baseMetric.get("uid");
    let fetch = new Traec.Fetch("tenant_admin_dispatch", "post");
    fetch.updateFetchParams({
      preFetchHook: (data) => {
        let post_data = {
          type: "MERGE_BASEMETRICS",
          payload: {
            from_metric_id: data.from_metric_id,
            to_metric_id: baseMetricId,
          },
        };
        return post_data;
      },
      postSuccessHook: (data) => {
        $(MODAL_ID).modal("hide");
        alert(JSON.stringify(data, null, 2));
        location.reload();
      },
    });

    setAndShowModal(MODAL_ID, {
      title: "Merge metric (this metric will be kept, from_metric removed)",
      body: (
        <BaseFormConnected
          params={fetch.params}
          fields={{ from_metric_id: { value: "", class: "col-sm-12" } }}
          forceShowForm={true}
          hideUnderline={true}
        />
      ),
    });
  }

  dropDownLinks() {
    let { showConversions, showRefs } = this.state;
    return [
      { name: "Edit", onClick: this.editBaseMetric },
      { name: "Merge", onClick: this.mergeBaseMetrics },
      {},
      {
        name: `${showConversions ? "Hide" : "Show"} Conversion Factors`,
        onClick: (e) => this.setState({ showConversions: !showConversions }),
      },
      {
        name: `${showRefs ? "Hide" : "Show"} Reporting Packages using`,
        onClick: (e) => this.setState({ showRefs: !showRefs }),
      },
    ];
  }

  render() {
    let { showConversions, showRefs } = this.state;
    let { baseMetric } = this.props;
    let rowNum = counter.metric++;
    let parentMetricId = baseMetric.get("parentmetric");
    return (
      <ErrorBoundary>
        <div
          className="row"
          style={{ borderTop: "1px solid #ddd", backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : "" }}
        >
          <div className="col-sm-1">{baseMetric.get("uid").substring(0, 8)}</div>
          <div className="col-sm-1">{parentMetricId ? parentMetricId.substring(0, 8) : null}</div>
          <div className="col-sm-1">{(baseMetric.get("conversion_base") || "").substring(0, 8)}</div>
          <div className="col-sm-2">{baseMetric.get("name")}</div>
          <div className="col-sm-4">
            <div dangerouslySetInnerHTML={{ __html: baseMetric.get("description") }} />
          </div>
          <div className="col-sm-1">{baseMetric.get("category")}</div>
          <div className="col-sm-1">{baseMetric.get("unit")}</div>
          <div className="col-sm-1">{<BSBtnDropdown links={this.dropDownLinks()} />}</div>
        </div>
        <BaseMetricConversionFactors baseMetric={baseMetric} show={showConversions} />
        <BaseMetricRefs baseMetric={baseMetric} show={showRefs} />
      </ErrorBoundary>
    );
  }
}

class BaseMetricAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.requiredFetches = [
      new Traec.Fetch("tenant_admin_basemetric", "list"),
      new Traec.Fetch("tenant_admin_category", "list"),
    ];
  }

  /**********************
     COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { basemetrics, companyId, categories } = this.props;

    let rows = basemetrics.map((bm, i) => <BaseMetricRow key={i} baseMetric={bm} categories={categories} />);

    let companyStr = companyId ? ` for company account ${companyId}` : null;

    return (
      <React.Fragment>
        <h3>BaseMetrics in Tenancy</h3>
        <p>This gives a list of all BaseMetrics within this tenancy {companyStr}</p>

        {/* Row header*/}
        <div className="row" style={{ fontWeight: "bold" }}>
          <div className="col-sm-1">id</div>
          <div className="col-sm-1">parentId</div>
          <div className="col-sm-1">conversion_base</div>
          <div className="col-sm-2">name</div>
          <div className="col-sm-4">description</div>
          <div className="col-sm-1">issue</div>
          <div className="col-sm-1">unit</div>
          <div className="col-sm-1">admin</div>
        </div>

        {rows}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { _companyId: companyId } = ownProps.match.params;
  let basemetrics = (state.getInPath("entities.baseMetrics.byId") || Traec.Im.Map()).toList();
  let categories =
    state
      .getInPath("entities.metricCategories.byId")
      ?.valueSeq()
      .sortBy((category) => category.get("name")) || Traec.Im.List();
  return { basemetrics, companyId, categories };
};

export default connect(mapStateToProps)(BaseMetricAdmin);
