import Octicon from "react-octicon";
import { Link } from "react-router-dom";
import React from "react";

export const ReportingPackageStatusItem = ({
  projectId,
  index,
  color,
  refId,
  refName,
  commitId,
  status,
  dates,
  depth,
}) => {
  if (!projectId) {
    return null;
  }
  return (
    <React.Fragment>
      <div key={index} className="row hover-row py-1" style={{ paddingLeft: `${depth}em`, minHeight: "39px" }}>
        <RefName projectId={projectId} refId={refId} refName={refName} />
        <Status status={status} />
        <Dates dates={dates} />
        <GoToReport projectId={projectId} refId={refId} commitId={commitId} />
      </div>
    </React.Fragment>
  );
};

const RefName = ({ projectId, refId, refName }) => {
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  return (
    <div className="col-sm-5 pl-1 text-muted">
      <u>
        <Octicon name="file" />
        <Link className="ml-1 text-muted" to={`/project/${projectId}/wpack/${refId}/`}>
          {refName}
        </Link>
      </u>
    </div>
  );
};

const Status = ({ status }) => {
  const badgeColor = status.includes("Pending Approval")
    ? "warning"
    : status.includes("Approved")
    ? "success"
    : "danger";

  return (
    <div className="col-sx-12 col-sm-2 px-sm-0 ">
      <h6>
        <span className={`badge badge-pill badge-${badgeColor} text-wrap  w-100  p-2 `}>{status}</span>
      </h6>
    </div>
  );
};

const Dates = ({ dates }) => {
  return <div className="col-sm-3">{dates}</div>;
};

const GoToReport = ({ projectId, refId, commitId }) => {
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  commitId = commitId ? commitId.substring(0, 8) : commitId;
  return (
    <div className="col-sm-2">
      <Link
        className="float-right btn btn-sm btn-outline-primary"
        to={`/project/${projectId}/wpack/${refId}/report/${commitId ? commitId : ""}`}
      >
        Go to Report
      </Link>
    </div>
  );
};
