import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import BaseFormConnected from "traec-react/utils/form";
import ProjectInviteItem from "./projectInviteItem";
import Octicon from "react-octicon";

const projectInviteFields = {
  email: { value: "", class: "col", endRow: true },
};

class ProjectInvites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
    };

    // Data required from the API for this Component
    this.requiredFetches = [new Traec.Fetch("project_invite_all", "list")];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { items } = this.props;
    if (!items || !items.size) {
      return (
        <div className="d-flex flex-column align-items-center">
          <Octicon name="verified" mega className="text-success my-2" />
          <p className="mb-0">No outstanding Project Invites</p>
        </div>
      );
    }

    const itemList = items
      .toList()
      .map((item, i) => <ProjectInviteItem key={i} index={i} invite={item} dispatch={this.props.dispatch} />);

    return (
      <>
        {itemList}
        <BaseFormConnected
          stateParams={this.state.formParams.stateParams}
          fetchParams={this.state.formParams.fetchParams}
          fields={projectInviteFields}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: state
    .getInPath("entities.projectInvites.byId")
    ?.filter((i) => i?.getInPath("project_discipline.project.name")),
  isAuthenticated: state.getInPath("auth.isAuthenticated"),
});

export default connect(mapStateToProps)(ProjectInvites);
